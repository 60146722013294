<template>
  <div class="container pt-3" v-show="PageTitle">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link :to="{ name: 'EducatorModules' }">
            Modules
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ PageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div
      class="d-flex"
      style="align-items:center;
                justify-content:space-between;"
    >
      <h4 class="mb-0">
        {{ PageTitle }}
      </h4>

      <!-- <div v-if="isRoleAdmin() || isRoleTeacher()">
        <template>
          <template>
            <el-tooltip
              class="mr-2"
              effect="dark"
              content="Regrade all (for after you edit your questions)"
              placement="top"
            >
              <el-button type="warning" size="small">
                <i class="fas fa-redo"></i>
              </el-button>
            </el-tooltip>
            <el-tooltip
              class="mr-2"
              effect="dark"
              content="Duplicate"
              placement="top"
            >
              <el-button class="m-0" type="warning" size="small">
                <i class="fas fa-copy"></i>
              </el-button>
            </el-tooltip>
            <el-tooltip
              class="mr-2"
              effect="dark"
              content="Edit"
              placement="top"
            >
              <router-link
                :to="{
                  name: '1'
                }"
              >
                <el-button type="warning" size="small">
                  <i class="fa fa-edit"></i>
                </el-button>
              </router-link>
            </el-tooltip>
            <el-tooltip
              class="mr-2"
              effect="dark"
              content="Change to draft"
              placement="top"
              v-if="true"
            >
              <el-button type="danger" size="small">
                <i class="fas fa-level-down-alt"></i>
              </el-button>
            </el-tooltip>
            <el-popover
              placement="top"
              width="250"
              trigger="hover"
              v-if="false"
            >
              <div class="text-center">
                <el-button type="success" size="small">
                  Public
                  <i class="fas fa-users"></i>
                </el-button>
                <el-button type="success" size="small">
                  Unlisted
                  <i class="fas fa-user-lock"></i>
                </el-button>
              </div>
              <el-button
                class="mr-2"
                type="success"
                size="small"
                slot="reference"
              >
                <i class="fas fa-upload"></i>
              </el-button>
            </el-popover>
            <el-tooltip
              class="mr-2"
              effect="dark"
              content="Remove"
              placement="top"
              v-if="false"
            >
              <el-button class="ml-0" type="danger" size="small">
                <i class="fas fa-trash-alt"></i>
              </el-button>
            </el-tooltip>
          </template>
        </template>
      </div> -->
    </div>
    <hr class="separate-line" />
    <el-card>
      <div>
        <el-form label-width="150px" label-position="top">
          <el-form-item label="Test Name">
            <b class="text-success">
              {{ PageTitle }}
            </b>
          </el-form-item>
          <el-form-item label="Questions">
            <b class="text-success">
              {{ showQuestions.length }}
            </b>
            Questions
          </el-form-item>
          <el-form-item label="Students" v-if="isRoleAdmin()">
            <el-button type="success" size="mini" style="width: 4rem;">
              <i class="fas fa-users"></i>
              {{ practice.exam.user_exams_count }}
            </el-button>
          </el-form-item>
          <el-form-item label="Date Created">
            {{ instant.formatGoogleTime(practice.created_at) }}
          </el-form-item>
        </el-form>
        <hr />
      </div>
      <router-link
        :to="{ name: 'EducatorModulePDF', query: $route.query }"
      >
        <el-button type="success" style="width: 100%;">
          <i class="fas fa-eye"></i>
          View PDF
        </el-button>
      </router-link>
      <QuestionsTable
        :showQuestion="true"
        :showPassage="true"
        :showOptions="true"
        :showAnswer="true"
        :showExplanation="false"
        :showLabels="false"
        :questions="showQuestions"
        :search="$route.query.search"
        :isAdmin="isRoleAdmin()"
        :showOrder="true"
      >
      </QuestionsTable>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import Browse from "@/views/tests/modules/browse/apis/browse.js";
import Common from "@/mixins/common.js";
import { instant } from "@ivy-way/material";
import $ from "jquery";
import roleMixin from "@/mixins/role";
import QuestionsTable from "@/views/questions/list/components/QuestionsTable.vue";

export default {
  metaInfo() {
    return {
      title: `${this.PageTitle} - ${this.CompanyName}`
    };
  },

  components: { QuestionsTable, Breadcrumb },

  mixins: [Common, roleMixin],

  props: [],
  data() {
    return {
      Rating: 5,
      showPassage: true,
      showQuestion: true,
      showOptions: true,
      showAnswer: false,
      showExplanation: false,
      showLabels: false,
      showCreateNewQuestionDialog: false,
      practice: {
        exam: {
          title: null,
          exam_questions: []
        },
        subject_type: null,
        subject_id: null
      },
      showQuestions: [],
      practices: [],
      subjects: []
    };
  },
  computed: {
    instant() {
      return instant;
    },
    PageTitle() {
      let title = this.practice.name
        ? this.getCompanyExamTitle(this.practice.name)
        : "";
      return title;
    },
    id() {
      return this.$route.params.id;
    },
    sectionType() {
      return this.$route.params.type;
    },
    isEdit() {
      let isEdit = 0;
      if (this.$route.query.isEdit) {
        isEdit = Number(this.$route.query.isEdit);
      }
      return isEdit;
    }
  },
  watch: {
    showPassage() {
      this.initMathJaxPlugin();
    },
    showExplanation() {
      this.initMathJaxPlugin();
    },
    showAnswer() {
      if (this.showAnswer) {
        this.showOptions = true;
      }
    },
    showOptions() {
      this.initMathJaxPlugin();
    },
    showQuestion() {
      this.initMathJaxPlugin();
    },
    questions() {
      this.initMathJaxPlugin();
    }
  },

  async mounted() {
    this.getTests();
    await this.initMathJaxPlugin();
    if (this.$route.query.subject_id) {
      this.practice.subject_id = this.$router.query.subject_id;
    }
    this.$nextTick(async () => {
      this.setImg();
    });
    let vm = this;
    window.onkeydown = function(e) {
      // 屏蔽ctrl+p 打印
      if (e.ctrlKey && e.keyCode == 80) {
        e.preventDefault();
        e.returnValue = false;
        return false;
      }
    };
  },

  methods: {
    async getTests() {
      const res = await Browse.getTests(this.id);
      this.practice = res.exam;
      if (
        this.practice.exam_sections &&
        this.practice.exam_sections.length > 0
      ) {
        this.practice.exam_sections[0].exam_questions.forEach(
          (question, index) => {
            this.showQuestions.push(question.question);
          }
        );
      }
    },
    setImg() {
      $(".small img").each(function() {
        if ($(this).attr("src") !== "") {
          $(this).attr("src", $(this).attr("src") + "?" + new Date().getTime());
        }
        $(this).attr("crossorigin", "anonymous");
      });
    },
    isSplit(nodes, index, pageHeight) {
      return (
        nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight &&
        nodes[index + 1] &&
        nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight > pageHeight
      );
    },

    initMathJaxPlugin() {
      this.$mathJax.loadMathJaxScript(() => {
        this.$mathJax.initMathJaxConfig();
        this.$mathJax.MathQueue([this.$refs["questions-all"]]);
      });
    },
    showSubject(id) {
      let subjectName = "";
      this.subjects.forEach(subject => {
        if (subject.id == id) {
          subjectName = this.titleCase(subject.name);
        }
      });
      return subjectName;
    }
  }
};
</script>

<style scoped>
#content {
  position: relative;
  width: 1184.56px;
  margin: 0 auto;
}
::v-deep .el-form-item__content {
  line-height: 1.5;
}
.question-card {
  cursor: move;
}

::v-deep .el-dialog__body {
  padding: 10px 20px;
}
::v-deep .el-form-item__label {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 2rem;
}
</style>
